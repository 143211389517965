@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");

.App {
  /* Uses Inter if internet there, otherwise defaults to system fonts */
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.place-page-div {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100vw;
}

/*

Header

*/

.website-name {
  font-size: 2rem;
  text-decoration: none;
  color: black;

  border-style: solid;

  border-width: 0px 0px 1px 0px;
  margin-bottom: 10px;

  font-weight: 500;
}

.website-name:hover {
  border-color: transparent;
}

.navbar-div {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 30px;
  margin-bottom: 30px;
}

[class^="navlink"] {
  /* match anything with navlink at the beginning. Used since place name comes after. */
  margin: 10px;
  text-decoration: none;
  color: gray;
}

[class^="navlink"]:hover {
  font-weight: bold;
}

.links-div {
  display: flex;

  justify-content: center;

  font-size: 1.25rem;
  flex-wrap: wrap;
}

/*

Photo Grid

*/

.thumbnail {
  width: 400px;
  margin-top: 10px;

  animation: fadeAnimation linear 0.5s; /* fade in thumbnails */
}

/* make sure has same width as thumbnail! */
.temp-thumb-div {
  width: 400px;
  height: 300px;
  margin-top: 10px;
}

.photo-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;

  /* gutter size offset */
  /* margin-left: 100px;  */

  justify-content: center;

  /* margin-left:200px;
    margin-right:200px;
     */

  width: auto;
}

.photo-grid-column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}

.image-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

/*
home
*/
.home-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro-text-div {
  margin-right: 100px;
  font-size: 1.4rem;
  width: 40vw;
}

.home-photo {
  width: 350px;
  height: auto;
}

.home-content-div {
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  justify-content: center;

  margin-top: 30px;
}

/* modals */

.modal-div {
  display: flex;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  align-items: center;
}

.full-image {
  display: block;
  max-width: 90vw; /* 90% of window width */
  max-height: 90vh; /* 90% of window height */
  width: auto;
  height: auto;
  z-index: 0;

  /* make magnifying glass cursor on top of image */
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;

  animation: fadeAnimation linear 0.2s;
}

/* for zooming into image on click */
.zoomed-image {
  transform-origin: left; /* allows for scrolling left-right but not top */
  transform: scale(3);
}

@keyframes fadeAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.close-modal-button {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  align-self: flex-end;

  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  align-self: flex-start;

  margin-top: 10px;
  position: absolute;
  top: 0;
  right: 30px;
}

.close-modal-icon {
  width: 30px;
}

/* .modal-image-figure {
    width:intrinsic;
} */

/*

    RESPONSIVENESS

*/

/* mobile devices < 480px width like iPhone */
@media only screen and (max-device-width: 480px) {
  .home-photo {
    width: 300px;
  }

  .thumbnail {
    width: 300px;
  }

  .links-div {
    margin-left: 30px;
    margin-right: 30px;
  }

  .photo-grid {
    margin-left: 50px;
    margin-right: 50px;
  }

  .intro-text-div {
    font-size: 1.2rem;
    margin: 0px;
    width: 80vw;
  }
}

/* tablets (ex: ipads) w/ max width given below */
@media only screen and (min-device-width: 481px) and (max-device-width: 950px) {
  .thumbnail {
    width: 350px;
  }

  .photo-grid {
    margin-left: 0px;
    margin-right: 0px;
  }

  .intro-text-div {
    font-size: 1.5rem;
    margin: 0px;
    width: 80vw;
  }
}

/* large tablets */
@media only screen and (min-device-width: 851px) and (max-device-width: 1050px) {
}

@media only screen and (min-width: 1060px) {
  /* styles for browsers larger than 960px; */
}
@media only screen and (min-width: 1440px) {
  /* styles for browsers larger than 1440px; */
}
@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
}
